// frontend/src/Home.js
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import React from 'react';
import Navbar from '../components/NavBar';

const Contact = () => {
    createBrowserRouter([{
        path: '/',
        element: <Navbar />
      }]);
  return (
    <>
        <Navbar />
      <h1>Contact</h1>
      <p></p>
    </>
  );
};

export default Contact;
