// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import About from './pages/About';
import Contact from './pages/Contact';
import NavBar from './components/NavBar';
import UserSpace from './pages/UserSpace';
import Help from './pages/Help';
import Test from './pages/Test';
import UnderConstruction from './pages/UnderConstruction';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/pages/about" element={<About />} />
        <Route path="/pages/contact" element={<Contact />} />
        <Route path="/pages/navbar" element={<NavBar />} />
        <Route path="/pages/userspace" element={<UserSpace />} />
        <Route path="/pages/help" element={<Help />} />
        <Route path="/pages/test" element={<Test />} />
        <Route path="/pages/UnderConstruction" element={<UnderConstruction />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);