
import React from 'react';
import Navbar from '../components/NavBar';

const Test = () => {

  return (
    <>
    
        <Navbar />

      <h1>Aide à l'utilisation</h1>
      <p>Développement du site en cours, merci de revenir plus tard.</p>
    </>
  );
};

export default Test;
