import React, { useEffect, useState } from 'react';
import Navbar from '../components/NavBar';
import API_URL from '../config';
import { Helmet } from 'react-helmet';

const checkServerStatus = async () => {
  try {
    const response = await fetch(`${API_URL}/healthcheck`);
    if (response.ok) {
      return 'defined'; // Route définie et le serveur répond
    } else {
      return 'undefined'; // Serveur en ligne mais route non définie
    }
  } catch (error) {
    if (error.name === 'TypeError') {
      return 'offline'; // Serveur hors ligne ou URL non valide
    } else {
      console.error('Error checking server status:', error);
      return 'error'; // Autres erreurs
    }
  }
};

const UnderConstruction = () => {
  const [serverStatus, setServerStatus] = useState('checking');

  useEffect(() => {
    const displayServerStatus = async () => {
      const status = await checkServerStatus();
      setServerStatus(status);
    };

    displayServerStatus();
  }, []);

  const statusMessage = {
    checking: 'Checking server status...',
    defined: '🟢 Le serveur est actif et la route est définie 🟢',
    undefined: '🟢 Le serveur est actif mais la route n\'est pas définie 🟢',
    offline: '🔴 Le serveur est hors ligne 🔴',
    error: '⚠️ Erreur lors de la vérification du statut du serveur ⚠️'
  }[serverStatus];

  return (
    <>
      <Navbar />
      <Helmet>

<title>🏗️ HerbalDrug PhytoSearch </title>

</Helmet>
      <h1>Page en cours de construction</h1>
      <div id="serverStatus">
        <p>{statusMessage}</p>
      </div>
      <p>Veuillez patienter</p>
    </>
  );
};

export default UnderConstruction;
