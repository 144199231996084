// frontend/src/Home.js

import React from 'react';
import Navbar from '../components/NavBar';
import { Helmet } from 'react-helmet';

const Userspace = () => {

  return (
    <>
        <Navbar />
        <Helmet>

<title>👤 HDPS Espace utilisateur 👤</title>

</Helmet>
      <h1>Espace utilisateur</h1>
      <p>Bientôt: possibilité d'inscription et de connexion!</p>
    </>
  );
};

export default Userspace;
