// frontend/src/Home.js

import React from 'react';
import Navbar from '../components/NavBar';
import { Helmet } from 'react-helmet';  
import './HomePage.css';

const HomePage = () => {

  return (
    <>
        <Navbar />
        <Helmet>

<title>HerbalDrug PhytoSearch</title>

</Helmet>
      <div >
        <h1>Accueil</h1>
        <p>Bienvenue sur la page d'accueil de notre site.</p>
      </div>
    </>
  );
};

export default HomePage;
