// frontend/src/Navbar.js
import "./NavBar.css";  
import React, { useState } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom'; // Import the useNavigate hook and Route component
import { Link } from 'react-router-dom'; // Importer Link depuis react-router-dom
import { NavLink } from 'react-router-dom'; // Importer NavLink depuis react-router-dom

const NavBar = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (event) => {
    event.preventDefault();
    // Rediriger vers la page "Site en Construction"
    navigate('/pages/underconstruction');
  };

  return (
    <div className="navbar">
      <div className="logo">
        {/* Ajoute ton logo ici */}
        <h1>Logo</h1>
      </div>
      <div className="search">
        <form onSubmit={handleSearch}>
          <input
            type="text"
            placeholder="Recherche..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button type="submit">Rechercher</button>
        </form>
      </div>
      <div className="user-actions">
        <Link to="/pages/userspace">Espace utilisateur</Link>
      </div>
      <div className="help">
        <NavLink to="/pages/about">A propos</NavLink>
        <NavLink to="/pages/help">Aide</NavLink>
      </div>
    </div>
  );
};

export default NavBar;
