// src/components/ConsentBanner.js

import React from 'react';

const ConsentBanner = ({ onAccept, onDecline }) => {
  return (
    <div style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: 'lightgray', padding: 0, textAlign: 'center' }}>
      <p> Nous utilisons des cookies sur ce site pour améliorer votre expérience. Acceptez vous leur utilisation? </p>
      <p> Les données d'utilisation de la base de données d'HerbalDrug PhytoSearch sont également collectées mais ces dernières sont anonymisées.</p>
      <button onClick={onAccept}>Accepter</button>
      <button onClick={onDecline}>Refuser</button>
    </div>
  );
};

export default ConsentBanner;