// frontend/src/Home.js

import React from 'react';
import Navbar from '../components/NavBar';
import { Helmet } from 'react-helmet';

const Help = () => {

  return (
    <>
        <Navbar />
        <Helmet>

<title>❓ HDPS: Aide</title>

</Helmet>
      <h1>Aide à l'utilisation</h1>
      <p>Développement du site en cours, merci de revenir plus tard.</p>
    </>
  );
};

export default Help;
