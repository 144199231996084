// src/App.js

import React, { useState, useEffect } from 'react';
import HomePage from './pages/HomePage';
import ConsentBanner from './components/ConsentBanner';

const App = () => {
  const [consent, setConsent] = useState(null);

  useEffect(() => {
    const savedConsent = localStorage.getItem('cookieConsent');
    if (savedConsent) {
      setConsent(savedConsent);
    }
  }, []);

  const handleAccept = () => {
    setConsent('accepted');
    localStorage.setItem('cookieConsent', 'accepted');
  };

  const handleDecline = () => {
    setConsent('declined');
    localStorage.setItem('cookieConsent', 'declined');
  };

  return (
    <div className="App">
      <HomePage />
      {consent === null && <ConsentBanner onAccept={handleAccept} onDecline={handleDecline} />}
    </div>
  );
};

export default App;