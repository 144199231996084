// frontend/src/Home.js

import React from 'react';
import Navbar from '../components/NavBar';
import { Helmet } from 'react-helmet';

const About = () => {

  return (
    <>
        <Navbar />
        
        <Helmet>

<title>ℹ️ HDPS: À propos</title>

</Helmet>
      <h1>A propos</h1>
      <p>HerbalDrug PhytoSearch est un projet en cours de développement. </p>
    </>
  );
};

export default About;
