// config.js
const API_URL = '93.29.201.105'; // Remplacez par l'URL valide de votre serveur

const fetchData = async () => {
  try {
    const response = await fetch(`${API_URL}/endpoint`);
    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

fetchData();

export default API_URL;

// A utiliser ainsi 

/*
// someFile.js
import API_URL from './config';

const fetchData = async () => {
  try {
    const response = await fetch(`${API_URL}/endpoint`);
    const data = await response.json();
    console.log(data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

fetchData();
*/
